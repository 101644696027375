<template>
  <a
    :class="$style.link"
    href="/"
  >
    <XLogo
      color="black"
      height="24px"
    />
  </a>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'SignerLogo',
});
</script>

<style lang="scss" module>
.link {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: auto;
  height: auto;
  padding: 0px;
  padding: var(--space-small-x);
  text-decoration: none;
  cursor: pointer;
}
</style>
